import React from "react";

import { TypeAnimation } from "react-type-animation";

import { MacBookImage, PatternImage } from "../../utils/images";
import { StyledContentBox, StyledHeading, StyledMacBookImage, StyledPatternImage, StyledRightBoxWrapper } from "./StyledComponent";

const RightIntro = () => {
  return (
    <StyledRightBoxWrapper>
      <StyledPatternImage src={PatternImage} alt="pattern" />
      <StyledContentBox>
        <StyledHeading variant="h1">Welcome to</StyledHeading>
        <StyledHeading variant="h1">
          <TypeAnimation className="auma-animation" sequence={["", 500, "A", 500, "Au", 500, "Aum", 500, "Auma", 500]} speed={1} repeat={Infinity} />
        </StyledHeading>
      </StyledContentBox>
      <StyledMacBookImage src={MacBookImage} alt="mac-book" />
    </StyledRightBoxWrapper>
  );
};

export default RightIntro;
