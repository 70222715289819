import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

import Loader from "../../components/Loader";

const AuthGuard = ({ children }) => {
  const { isLoggedIn, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, loading]);

  if (!isLoggedIn) return <Loader loading={true} />;

  return children;
};

export default AuthGuard;
