import { Box, styled, Typography } from "@mui/material";

export const MLayoutContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: "100vh",
  height: "100%",
  display: "flex",
}));

export const StyledRightBoxWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: `${theme.spacing(4, 4, 0, 4)}`,
  height: "100%",
  position: "relative",
}));

export const StyledPatternImage = styled("img")(() => ({
  width: "80%",
  position: "absolute",
  top: "8%",
}));

export const StyledContentBox = styled(Box)(({ theme }) => ({
  width: "80%",
  position: "absolute",
  top: "16%",
  left: "18%",

  [theme.breakpoints.down("lg")]: {
    left: "10%",
  },

  [theme.breakpoints.down("md")]: {
    left: "10%",
  },
}));

export const StyledMacBookImage = styled("img")(() => ({
  height: "74%",
  width: "55%",
  position: "absolute",
  bottom: "0",
  right: "0",
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  letterSpacing: "-0.01em",
  color: theme.palette.text.primary,
}));
