import { Box, List, styled, Typography, useTheme } from "@mui/material";

import { useSelector } from "../../../../store";
import NavItem from "./NavItem";

import { RiBriefcaseLine, RiBuilding2Line } from "react-icons/ri";
import { useParams } from "react-router-dom";

export const StyledHead = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.2),
  textTransform: "uppercase",
  color: theme.palette.placeHolder,
}));

const icons = {
  RiBriefcaseLine,
  RiBuilding2Line,
};

const DrawerContent = () => {
  const theme = useTheme();
  const param = useParams();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navGroups = [
    {
      id: "jobs",
      title: "Jobs",
      type: "item",
      url: `jobs/${param.id}`,
      icon: icons.RiBriefcaseLine,
    },
    {
      id: "company",
      title: "Company",
      type: "item",
      url: `company/${param.id}`,
      icon: icons.RiBuilding2Line,
    },
  ].map((item) => {
    return <NavItem key={item.id} item={item} />;
  });

  return (
    <Box sx={{ pt: 2, padding: theme.spacing(5, 0), flex: 1 }}>
      <StyledHead variant="h6">Main</StyledHead>
      <List sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}>{navGroups}</List>
    </Box>
  );
};

export default DrawerContent;
