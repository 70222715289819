import { useEffect } from "react";

import ThemeCustomization from "./themes";
import ScrollTop from "./utils/ScrollTop";
import { JWTProvider } from "./contexts/JWTContext";
import Routes from "./routes";
import { GOOGLE_PLACES_API_KEY } from "./utils/environment";

const App = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ThemeCustomization>
      <ScrollTop>
        <JWTProvider>
          <Routes />
        </JWTProvider>
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default App;
