import { useState } from "react";

import { Box, IconButton, Menu, useTheme } from "@mui/material";

const CMenu = ({ children, anchorOrigin, transformOrigin, Icon, ...props }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <Box>
      <IconButton onClick={handleClick} sx={{ borderRadius: "8px" }}>
        <Icon size={theme.icon.size.lg} color={theme.palette.text.secondary} />
      </IconButton>

      <Menu
        className="mui-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...props}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default CMenu;
