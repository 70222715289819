import React from "react";

import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import RightIntro from "./RightIntro";
import { MLayoutContainer } from "./StyledComponent";

const MinimalLayout = () => {
  return (
    <MLayoutContainer>
      <Box sx={{ width: "45%" }}>
        <Outlet />
      </Box>
      <Box sx={{ width: "55%" }}>
        <RightIntro />
      </Box>
    </MLayoutContainer>
  );
};

export default MinimalLayout;
