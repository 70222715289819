import React, { useEffect, useState } from "react";

import { Box, Dialog, DialogActions, DialogContent, MenuItem, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { RiArrowRightSLine, RiSettings3Line, RiLogoutBoxLine, RiInformationFill } from "react-icons/ri";

import Menu from "../../../components/Menu";
import Button from "../../../components/ui/Button";
import StatusModal from "../../../components/StatusModal";

import { dispatch, useSelector } from "../../../store";
import { logoutSuccess } from "../../../store/slices/user";

import { cookieStorage } from "../../../utils/cookie";

const DrawerFooterStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-start" : "center",
  padding: theme.spacing(6, 1, 1, 1),
  gap: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,

  "& img": {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  "& h6": {
    color: theme.palette.text.primary,
  },

  "& p": {
    width: "135px",
    color: theme.palette.text.secondary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  },
}));

const DrawerFooter = ({ open }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  const [isLogoutDialogOpen, setLogoutDialog] = useState(false);
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    photo: "",
  });

  useEffect(() => {
    if (user) {
      setUserDetail({
        name: user.fullName,
        email: user.email,
        photo: user.profileImage,
      });
    }
  }, [user]);

  const logout = () => {
    cookieStorage.removeItem("jwt_uuid");
    cookieStorage.removeItem("uuid");
    cookieStorage.clear();
    dispatch(logoutSuccess());
    navigate("/login");
  };

  return (
    <>
      <DrawerFooterStyled theme={theme} open={open}>
        <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(3) }}>
          <img src={userDetail.photo} alt="user" />
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">{userDetail.name || "N/a"}</Typography>
            <Typography title={userDetail.email || "N/a"} variant="body2">
              {userDetail.email || "N/a"}
            </Typography>
          </Box>
        </Box>
        <Menu
          Icon={RiArrowRightSLine}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
        >
          <MenuItem>
            Settings <RiSettings3Line size={theme.icon.size.lg} color={theme.palette.text.secondary} />
          </MenuItem>
          <MenuItem onClick={() => setLogoutDialog(true)}>
            Logout <RiLogoutBoxLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
          </MenuItem>
        </Menu>
      </DrawerFooterStyled>

      <Dialog fullWidth maxWidth="xs" open={isLogoutDialogOpen} className="confirm-dialog">
        <DialogContent>
          <Box className="status-pill">
            <StatusModal variant="Red" icon={<RiInformationFill size={theme.icon.size.lg} />} />
          </Box>
          <Typography variant="body1">Are You Sure You Want to Log Out?</Typography>
          <Typography variant="subtitle1">You are about to log out of your account.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDialog(false)} fullWidth title="Cancel" variant="neutral" state="Stroke" />
          <Button onClick={logout} fullWidth title="Log Out" variant="primary" state="Filled" />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DrawerFooter;
