import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  isCompanyCreated: false,
  company: null,
  companyList: null,
  isFormChanged: false,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    companyDetailsSuccess(state, action) {
      const { company } = action.payload;
      state.company = company.length > 0 ? company[0] : null;
      state.isCompanyCreated = company.length > 0 ? true : false;
      state.companyList = company;
      state.loading = false;
    },
    createEditCompanySuccess(state, action) {
      const { company } = action.payload;
      state.company = company;
      state.isCompanyCreated = true;
      state.companyList = [...state.companyList, company];
      state.loading = false;
    },
    formChangedSuccess(state, action) {
      const { isChanged } = action.payload;
      state.isFormChanged = isChanged;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const { companyDetailsSuccess, createEditCompanySuccess, formChangedSuccess, loadingSuccess } = companySlice.actions;

export default companySlice.reducer;
