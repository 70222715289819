import React, { useEffect } from "react";
import { cookieStorage } from "../utils/cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userId = cookieStorage.getItem("uuid");
    if (userId) {
      navigate(`/company/${userId}`);
    } else {
      navigate(`/login`);
    }
  }, []);

  return <Loader loading={true} />;
};

export default Home;
