import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "./assets/styles/third-party.css";
import "./assets/styles/dialog.css";
import "./assets/styles/tooltip.css";

import "react-grid-layout/css/styles.css";

import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

import App from "./App";
import Snackbar from "./components/Snackbar";
import { store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
      <Snackbar />
    </BrowserRouter>
  </ReduxProvider>
);
