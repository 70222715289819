import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from "@mui/material";

import { RxHamburgerMenu } from "react-icons/rx";

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    margin: "0 32px",
    width: `calc(100% - 344px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  width: "100%",
  position: "fixed",
  height: "88px",
  zIndex: 1,
}));

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  const mainHeader = (
    <Toolbar sx={{ minHeight: "88px" }}>
      {matchDownMD && (
        <IconButton
          disableRipple
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{
            bgcolor: open ? "" : "grey.100",
            ml: { xs: 0, lg: -2 },
            borderRadius: "8px",
          }}
        >
          <RxHamburgerMenu size={theme.icon.size.lg} color={theme.palette.text.secondary} />
        </IconButton>
      )}
      <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }} />
    </Toolbar>
  );

  const appBar = {
    position: "fixed",
    elevation: 0,
    sx: {
      minHeight: "88px",
      background: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  };

  return (
    <StyledHeader>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar} style={{ width: `calc(100% - 64px)`, margin: `0 32px` }}>
          {mainHeader}
        </AppBar>
      )}
    </StyledHeader>
  );
};

export default Header;
