import { dispatch } from "../store";
import { loadingSuccess, userSuccess } from "../store/slices/user";
import axiosServices from "../utils/axios";
import { cookieStorage } from "../utils/cookie";

export const getUserService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/users/${id}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(userSuccess({ user: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const updateUserNameService = (id, name) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.patch(
        `/users/${id}`,
        { fullName: name },
        {
          headers: {
            Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
            "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
          },
        }
      );
      dispatch(userSuccess({ user: response.data.data }));
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};
