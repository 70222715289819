import Logo from "../assets/images/logo.svg";
import FullLogo from "../assets/images/full_logo.svg";
import FullDisableLogo from "../assets/images/full_disable_logo.svg";
import PatternImage from "../assets/images/pattern.png";
import MacBookImage from "../assets/images/macbook.png";
import UserPurpleImage from "../assets/images/user_purple.png";
import UserSharedLineImage from "../assets/images/user_shared_line.png";
import MailCheckLineImage from "../assets/images/mail_check_line.png";
import ResizeIcon from "../assets/images/resize.png";
import EmptyStateIcon from "../assets/images/empty_state.png";
import SliderMarkIcon from "../assets/images/slider_mark.svg";
import SliderThumbMarkIcon from "../assets/images/slider_thumb_mark.svg";
import AvatarIcon from "../assets/images/avatar.svg";
import SettingIcon from "../assets/images/settings.svg";
import UploadCloudIcon from "../assets/images/upload-cloud.svg";
import NextStageIcon from "../assets/images/next-stage.svg";
import SparkleIcon from "../assets/images/sparkle.svg";
import InterviewSuccessIcon from "../assets/images/interview-success.svg";
import InterviewExpiredIcon from "../assets/images/interview-expired.svg";
import UploadCompanyIcon from "../assets/images/upload_company.svg";
import VoiceCallCompletedIcon from "../assets/images/voice_call_completed.svg";
import VoiceCallExpiredIcon from "../assets/images/voice_call_expired.svg";
import MagicFillIcon from "../assets/images/magic_fill.svg";

export {
  SparkleIcon,
  NextStageIcon,
  UploadCloudIcon,
  SettingIcon,
  AvatarIcon,
  SliderThumbMarkIcon,
  SliderMarkIcon,
  EmptyStateIcon,
  PatternImage,
  MacBookImage,
  UserPurpleImage,
  UserSharedLineImage,
  MailCheckLineImage,
  ResizeIcon,
  Logo,
  FullLogo,
  FullDisableLogo,
  InterviewSuccessIcon,
  InterviewExpiredIcon,
  UploadCompanyIcon,
  VoiceCallCompletedIcon,
  VoiceCallExpiredIcon,
  MagicFillIcon,
};
