import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  job: null,
  jobList: [],
  hasMorePage: false,
  currentPage: 1,
  totalJobs: 0,
  kanbanStatus: ["Rejected", "Applied", "Top Candidates", "AI Phone Interview Pending", "AI Phone Interview Completed"],
  kanban: {
    Rejected: {
      orders: [],
      items: [],
      name: "Rejected",
    },
    Applied: {
      orders: [],
      items: [],
      name: "Applied",
    },
    "Top Candidates": {
      orders: [],
      items: [],
      name: "Top Candidates",
    },
    "AI Phone Interview Pending": {
      orders: [],
      items: [],
      name: "AI Phone Interview Pending",
    },
    "AI Phone Interview Completed": {
      orders: [],
      items: [],
      name: "AI Phone Interview Completed",
    },
  },
  candidateDetail: null,
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    jobDetailsSuccess(state, action) {
      const { job } = action.payload;
      state.job = job;
      state.loading = false;
    },
    jobListSuccess(state, action) {
      const { data } = action.payload;
      state.jobList = [...state.jobList, ...data.jobs];
      state.hasMorePage = data.currentPage < data.totalPages;
      state.currentPage = data.currentPage;
      state.totalJobs = data.totalJobs;
      state.loading = false;
    },
    jobCreateSuccess(state, action) {
      const { job } = action.payload;
      state.jobList = [...state.jobList, job];
      state.job = job;
      state.totalJobs += 1;
      state.loading = false;
    },
    jobUpdateSuccess(state, action) {
      const { job } = action.payload;
      const updatedJobList = state.jobList.map((j) => (j._id === job._id ? job : j));
      state.jobList = updatedJobList;
      state.job = job;
      state.loading = false;
    },
    jobOrderIdSuccess(state, action) {
      const { orderList } = action.payload;
      Object.entries(orderList).map((item) => {
        state.kanban[item[0]].orders = item[1];
      });
      state.loading = false;
    },
    jobUserListSuccess(state, action) {
      const { userList } = action.payload;

      Object.keys(state.kanban).forEach((key) => {
        state.kanban[key].items = [];
      });

      userList.forEach((user) => {
        const column = state.kanban[user.status];
        if (column) {
          const index = column.orders.indexOf(user._id);
          if (index !== -1) {
            column.items[index] = user;
          } else {
            column.items.push(user);
          }
        }
      });
      state.loading = false;
    },
    updateKanbanSuccess(state, action) {
      const { kanban } = action.payload;
      state.kanban = kanban;
      state.loading = false;
    },
    candidateDetailSuccess(state, action) {
      const { candidateDetail } = action.payload;
      state.candidateDetail = candidateDetail;
      state.loading = false;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const {
  jobListSuccess,
  jobDetailsSuccess,
  jobCreateSuccess,
  jobUpdateSuccess,
  jobOrderIdSuccess,
  jobUserListSuccess,
  updateKanbanSuccess,
  candidateDetailSuccess,
  loadingSuccess,
} = jobSlice.actions;

export default jobSlice.reducer;
