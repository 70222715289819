import axios from "axios";

import { API_URL } from "./environment";
import { cookieStorage } from "./cookie";
import { openErrorSnackbar } from "../helper/snackbar";
import { SnackBarVariant } from "../enums/snackVariant";

const axiosServices = axios.create({ baseURL: API_URL + "/api/v1" });

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.status === 407) {
      openErrorSnackbar({
        title: "Something went wrong!",
        message: error.response.data.message,
        variant: SnackBarVariant.ErrorNotification,
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        transition: "SlideLeft",
      });
      cookieStorage.clear();
      window.location.href = "/login";
    } else {
      openErrorSnackbar({
        message: error.response.data.message,
        variant: SnackBarVariant.Error,
      });
      return Promise.reject(error);
    }
  }
);

export default axiosServices;
